@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700;900&display=swap');

.App-header {
  /* background: linear-gradient(135deg, #92C0F3, #ECAAEE); */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: calc(7px + 2vmin);
  background-color: white;
}


.navbar{
  background-color: #292929;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 300;
  overflow: hidden;
  max-width: 2800px;
  /* z-index: 10000; */
  
  position: relative;
  top:0;
  width: 100%;
  height: 50px;
  font-family: 'Montserrat', sans-serif;
  
}

.navbar link{
  color: white;
}

.navbar__item {
  padding: 16px 16px;
  cursor: pointer;
  vertical-align: middle;
  text-decoration: none;
}

.navbar__item:link{
  color: white;
  text-decoration: none;
}

.navbar__item:visited{
  color: white;
  text-decoration: none;
}


.navbar__item:active{
  font-weight: 700;
}


.footer{
  background-color: #292929;
  
  color: white;
  font-weight: 300;
  overflow: hidden;
  max-width: 2800px;
  position: relative;
  bottom:0;
  
  width: 100%;
  /* height: 50px; */
  font-family: 'Montserrat', sans-serif;
}
.wrapf{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  padding: 50px 50px;
}

.secWrap{
  width: 50%;
}

.titoloF{
  font-size: 1.5rem;
  font-weight: 700;
}

.sottotitolof{
  font-size: 1.3rem;
  font-weight: 700;
  line-height: 1.5;

}
.testof{
  font-size:1.2rem;
  font-weight: 500;
}
.frasetta{
  text-align: center;
  font-weight: 500;
  font-size: 0.8rem;
}

.main{
  position: relative;
  min-height: 100vh;
  font-family: 'Montserrat', sans-serif;
  color:#292929
}

.sectionsWrap{
  min-height: 70vh;
  
  display: flex;  
  align-items: baseline;
  justify-content: center;
  
}


.section{
  display:flex;
  flex-direction: row;
  justify-content: center;
  align-items: baseline;
  padding-top: 30vh;
  padding-bottom: 10vh;
  height: 30vh;
}
.corpo1{
  padding-right: 20px;
  text-align: right;
  font-size: 4.5rem;
  line-height: 0.01;
  font-weight: 900;
}
.corpo2{
  padding-left: 20px;
  text-align: left;
  font-size: 2rem;
  font-weight: 500;
}


.slidess{
  align-items: center;
}

/* Slideshow */

.slideshow {
  margin: 0 auto;
  overflow: hidden;
  max-width: 100%;
  min-width: 100vw;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;

  height: 70vh;
  width: 100%;
  
}
.imagex{
  width:100%;
  object-fit: cover;
  overflow: hidden;
  max-height: 80vh;
}

/* Buttons */

.slideshowDots {
  text-align: center;
  
}

.slideshowDot {
  display: inline-block;
  height: 15px;
  width: 15px;
  border-radius: 50%;

  cursor: pointer;
  margin: -15px 7px 0px;

  background-color: #c4c4c4;
}
.slideshowDot.active {
  background-color: #292929;
}

.mail{
  color: #292929;
  text-decoration: none;

}